.grid {
    margin: 0 auto;
}
.grid-sizer,
.grid-item {
    width: 140px;
    height:auto;
    margin-bottom: 1rem;
    @include for-tablet-portrait-up {
        width: 350px;
        height:233px;
        object-fit: cover;
    }
}