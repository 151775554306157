@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

@import "modules/variables";

// Import Bootstrap !not needed if you use WebPixels UI, import the UI instead
// @import 'vendor/bootstrap.scss';
@import 'vendor/webpixels.scss';


// Modules
@import "modules/media_query_mixins";
@import "modules/typography";
@import "modules/buttons";
@import "modules/form";

// Blocks
@import "blocks/masonry";
@import "modules/global";

.hero-wrapper{
    position:relative;
    &:before{
        content:'';
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        background-color:rgba(black, 0.3);
        z-index: 1;
    }
    background:{
        repeat:no-repeat;
        size:cover;
        position:center;
    }
    .hero-content{
        position: relative;
        z-index: 2;
    }
    padding-top: 5rem;
    padding-bottom: 5rem;
    text-align: center;
    @include for-tablet-landscape-up{
        padding-top: 12rem;
        padding-bottom: 12rem;
    }
}

.hero-wrapper-xs{
    position:relative;
    &:before{
        content:'';
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        background-color:rgba(black, 0.3);
        z-index: 1;
    }
    background:{
        repeat:no-repeat;
        size:cover;
        position:center;
    }
    .hero-content{
        position: relative;
        z-index: 2;
    }
    padding-top: 4rem;
    padding-bottom: 4rem;
    text-align: center;
    @include for-tablet-landscape-up{
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
}

.page-title{
    border-top:1px solid rgba(grey, 0.2);
    padding-top: 1.5rem;
    h1{
        color:$grey;
    }
}

.bordered-top{
    border-top:1px solid rgba(grey, 0.2);
    padding-top: 1.5rem;
}

.content-block{
    border-bottom:1px solid rgba(grey, 0.2);
    padding-bottom: 1.5rem;
}

.content-block-title{
    border-bottom:1px solid rgba(grey, 0.2);
    padding-bottom: 1.5rem;
}

.list-group-wrapper{
    .list-group{
        border:none;
        .list-group-item{
            border:none;
            padding: 0.75rem 0;
            .avatar{
                font-size: 1.75rem;
            }
        }
    }
    &.icon-details{
        .list-group-item{
            margin: 0;
            padding: 0 0 0.5rem;
            gap:0.5rem;
        }
        .avatar{
            justify-content: flex-start;
            flex:0;
            width:auto;
            height:auto;
            span{
                font-size: 1.2rem;
            }
        }
        span{
            justify-content: flex-start;
        }
    }
}


.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  /* ideally, transition speed should match zoom duration */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

@media all and (max-width:767px) {
    .flex-wrap-mobile{
        flex-wrap: wrap;
    }
}

.bordered-top{
    border-top:1px solid rgba(grey, 0.2);
    padding-top: 1.5rem;
}

.black-link{
    color:$grey;
    &:hover{
        color:$grey;
    }
}

.fixed-height{
    width:auto;
    height:220px;
}

.width-auto{
    width: auto;
}


// START RATING

.rating-form {
    display: inline-block;
    position: relative;
    height: 30px;
    line-height: 30px;
    font-size: 30px;
  }
  
  .rating-form label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    cursor: pointer;
  }
  
  .rating-form label:last-child {
    position: static;
  }
  
  .rating-form label:nth-child(1) {
    z-index: 5;
  }
  
  .rating-form label:nth-child(2) {
    z-index: 4;
  }
  
  .rating-form label:nth-child(3) {
    z-index: 3;
  }
  
  .rating-form label:nth-child(4) {
    z-index: 2;
  }
  
  .rating-form label:nth-child(5) {
    z-index: 1;
  }
  
  .rating-form label input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
  
  .rating-form label .icon {
    float: left;
    color: transparent;
  }
  
  .rating-form label:last-child .icon {
    color: rgba($grey, 0.2);
  }
  
  .rating-form:not(:hover) label input:checked ~ .icon,
  .rating-form:hover label:hover input ~ .icon {
    color: rgba(var(--x-warning-rgb));
    cursor: pointer;
  }
  
  .rating-form label input:focus:not(:checked) ~ .icon:last-child {
    color: rgba($grey, 0.2);
    cursor: pointer;
  }

  // END RATING

  .default-form{
    legend{
        color: $grey;
        display: block;
        font-weight: 500;
        font-size: var(--x-body-font-size);
        float: none;
    }
  }

.navbar-expand-lg{
    .navbar-brand{
      width:50px;
      height:50px;
      padding:0;
      img{
          width:50px;
          height:50px;
      }
      @include for-tablet-landscape-up{
          width:70px;
          height:70px;
          img{
              width:70px;
              height:70px;
          }   
      }
    }
}